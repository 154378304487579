<template lang="html">
  <div
    class="legal-view-container"
    :style="($vuetify.breakpoint.smAndDown) ? 'padding: 0 3% 0 3%;' : ''"
  >
    <div
      v-for="(topic, i) in topics"
      :key="`topic${i}`"
    >
      <h1>
        {{ topic.headline }}
      </h1>
      <div
        class="legal-text-container"
        v-if="topic.paragraphs.length > 0"
      >
        <p
          v-for="(paragraph, j) in topic.paragraphs"
          :key="`paragraph${i}-${j}`"
        >
          {{ paragraph }}
        </p>
      </div>
      <p
        class="legal-text-container"
        v-if="topic.thirdPartyLinks.length > 0"
      >
        <a
          :href="`${link.link}`"
          v-for="(link, j) in topic.thirdPartyLinks"
          :key="`link${i}-${j}`"
        >
          {{ link.name }}<br />
        </a>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  components: {

  },

  data: () => ({
    topics: [
      {
        headline: 'Contact Information',
        paragraphs: [
          'TAKT.ing GmbH',
          'Alte Bergstraße 436',
          '86899 Landsberg am Lech',
          'E-Mail: info@takting.com',
          'HRB 34165',
          ' ', // alt + 255, invisible character (not white space)
          'Geschäftsführung:',
          'Dipl.-Ing. Marco Binninger',
          'Janosch Dlouhy M.Eng',
        ],
        thirdPartyLinks: [ ],
      },
      {
        headline: 'Disclaimer',
        paragraphs: [
          'All rights reserved, unless expressly stated otherwise. All contents merely provide a non-binding overview of the company, the product, the vision and our goals. For the correctness, completeness or topicality of the data no guarantee is taken over.',
        ],
        thirdPartyLinks: [ ],
      },
      {
        headline: 'Legal Information',
        paragraphs: [
          'The contents of this website are regularly adapted. We reserve the right to make changes or additions to the information provided without prior notice. We are not liable for direct or indirect damages arising from or in connection with information provided on this website. The content of this website is protected by copyright.',
        ],
        thirdPartyLinks: [ ],
      },
      {
        headline: 'Data Protection',
        paragraphs: [
          'We refer to our privacy policy. If you have any questions, please contact us at the above contact details.',
        ],
        thirdPartyLinks: [ ],
      },
      {
        headline: '3rd Party Software',
        paragraphs: [ ],
        thirdPartyLinks: [
          {
            name: 'axios',
            link: 'https://github.com/axios/axios/blob/master/LICENSE',
          },
          {
            name: 'core-js',
            link: 'https://github.com/zloirock/core-js/blob/master/LICENSE',
          },
          {
            name: 'vue',
            link: 'https://github.com/vuejs/vue/blob/dev/LICENSE',
          },
          {
            name: 'vue-axios',
            link: 'https://github.com/imcvampire/vue-axios/blob/master/LICENSE',
          },
          {
            name: 'vue-cookies',
            link: 'https://github.com/cmp-cc/vue-cookies/blob/master/LICENSE',
          },
          {
            name: 'vue-router',
            link: 'https://github.com/vuejs/vue-router/blob/dev/LICENSE',
          },
          {
            name: 'vuetify',
            link: 'https://github.com/vuetifyjs/vuetify/blob/master/LICENSE.md',
          },
          {
            name: 'SLIM',
            link: 'https://github.com/slimphp/Slim/blob/4.x/LICENSE.md',
          },
          {
            name: 'Doctrine ORM',
            link: 'https://github.com/doctrine/orm/blob/master/LICENSE',
          },
          {
            name: 'Doctrine DBAL',
            link: 'https://github.com/doctrine/dbal',
          },
          {
            name: 'Doctrine common',
            link: 'https://github.com/doctrine/common',
          },
          {
            name: 'firebase php-jwt',
            link: 'https://github.com/firebase/php-jwt/blob/master/LICENSE',
          },
          {
            name: 'aws-sdk-php',
            link: 'https://github.com/aws/aws-sdk-php/blob/master/LICENSE.md',
          },
          {
            name: 'tiptap',
            link: 'https://github.com/ueberdosis/tiptap/blob/main/LICENSE.md',
          },
          {
            name: 'vue-country-flag',
            link: 'https://github.com/P3trur0/vue-country-flag/blob/master/LICENSE',
          },
        ],
      },
    ],
  }),
}
</script>

<style lang="css" scoped>
p {
  font-weight: 300;
}
a {
  font-weight: 300;
  font-size: 18pt;
}
.legal-text-container {
  padding: 10px 0 30px 0;
  text-align: left;
}
.legal-view-container {
  padding: 0 33% 0 3%;
}
</style>
